import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import Ucapan from '../components/ucapan'
import { Gift } from '../components/gift'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/ghea.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/ghea/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = 'rgb(209,183,113)'
let mainfont = 'rgb(209,183,113)'
let black = 'rgb(38,38,38)'

let id_temp = 'mutia-raihan'
let id = 'vivil-febri'
let inisial_co = 'Febri'
let inisial_ce = 'Vivil'
let lengkap_co = 'Febrian Dwi Rahmadan, S.KM'
let lengkap_ce = 'Sefillaili Wardatus Istifaroh, S.KM'
let bapak_co = 'Bpk. -'
let ibu_co = 'Ibu Daswati'
let bapak_ce = "Bpk. H. Akhmad Hariadi "
let ibu_ce = "Ibu Umi Khafsah"
let ig_co = "febrikptz_"
let ig_ce = "vivilswif"

let foto_ce = pw("nabila-leo", "Individual 2.jpg")
let foto_co = pw("nabila-leo", "Individual 1.jpg")
let waktunikah = "12/20/2020"

let modal = gambar(pw(id, "modal.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520')
let openlogo = pw(id, "logo.png")

let gmaps = "https://g.page/vanderwicjkgombong?share"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NDVucWxwanY4OWE4OXJubW91cXA2Nm9qMTcgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ['MHR04489-1.jpg',
    'MHR04537-1.jpg',
    'MHR04549.jpg',
   'MHR04575.jpg',
    'MHR04578.jpg',
    'MHR04605.jpg',
]
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift: false,
            showgift1: false
        }
    }
    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date("2021","0","10","9").getTime();
        // Update the count down every 1 second
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);

        let s= this.useQuery().get('s')
        if(s=="q"){
            this.setState({sesi:1})
        }else if(s=="w"){
            this.setState({sesi:2})
        }else if(s=="e"){
            this.setState({sesi:3})
        }else{
            this.setState({sesi:0})
        }
    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }

    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, showgift1 } = this.state
        
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id,v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: '#171717',
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',

                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url("${modal}")`,
                        }}>
                           

                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-md-3`}
                                     style={{ marginTop: '0', color: 'white' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}
                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white', borderColor: 'white' }}>
                                    Open Invitation
                </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }} className="position-relative">
                            <Container fluid id='g3-header' className='position-fixed' style={{
                            top:0,left:0,zIndex:'0',
                            backgroundImage: `url("${modal}")`,
                        }}>                        


                        </Container>
                               <div style={{zIndex:'1',position:'relative'}}>
                               <Container className="dinny px-3 py-3" style={{zIndex:'1'}}>
                                    <Item>
                                        <Col xs={6} md={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className="fs24 text-center px-3">
                                            <span className="fs16" style={{ color: "white" }}>
                                                And one of His signs is that He created for you spouses
                                                from among yourselves so that you may find comfort in them.
                                                And He has placed between you compassion and mercy.
                            Surely in this are signs for people who reflect. <br /><br />(Ar-Rum :21)
                        </span>
                                        </p>
                                    </Item>
                                    {/* <div className="embed-responsive embed-responsive-4by3 mb-3">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/-ZNyQJlYyKQ"></iframe>
                                    </div> */}
                                    <Item>
                                        <div className="w-100">
                                            <img className="w-100 img-fluid"
                                                src={gambar(pw(id,"MHR04578.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520')} />
                                        </div>
                                    </Item>
                                </Container>

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset","covid-white.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container id='name-ghea' className="py-5 dinny tinos" >
                                    <Row className="justify-content-center pb-3">
                                    
                                    </Row>
                                    <h1 >
                                        {lengkap_ce}
                                    </h1>
                                    <Item>

<img src={logoig} className='btn p-0'
    onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
</Item>
                                    
                                    <h1>
                                        &
                                    </h1>

                                    <Row className="justify-content-center py-3">
                                   
                                    </Row>
                                    <h1>
                                        {lengkap_co}

                                    </h1>
                                    <Item>

                                    <img src={logoig} className='btn p-0'
                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                    </Item>
                                    <Item>
                                        <p className="w-100 text-center pt-3">
                                        Keluarga yang Berbahagia<br/> 
                                        KEL H. Akhmad Hariadi <br/>
                                        & <br/>
                                        KEL Ibu Daswati
                                        </p>
                                    </Item>
                                    

                                    <Container id='sectiongold57'>
                                    <div className='py-3'>

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: 'white' }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{ color: black }}>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{ color: black }}>
                                                                Days
                                                        </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' style={{ color: black }}>:</div>
                                                    <div className='item' >
                                                        <Item>
                                                            <div style={{ color: black }}>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{ color: black }}>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' style={{ color: black }}>:</div>
                                                    <div className='item' >
                                                        <Item>
                                                            <div style={{ color: black }}>
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{ color: black }}>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' style={{ color: black }}>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{ color: black }}>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{ color: black }}>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>


                                    <h2 className="pt-3" style={{ color: cmain }}>
                                        <b>
                                            Sunday, January 10th , 2021
                    
                                        </b>
                                    </h2>
                                    <h2 className="pt-3" style={{ color: 'white' }}>
                                        <b>
                                           Akad Nikah
                    
                                            </b><br/>
                                            07.00 - Selesai
                                    </h2>
                                    <h2 className="pt-3" style={{ color: 'white' }}>
                                        <b>
                                           Resepsi
                    
                                            </b><br/>
                                            Sesi 1 : 09.00- 10.00<br/>
                                            Sesi 2 : 10.00 - 11.00 <br/>
                                            Sesi 3 : 11.00 - 12.00
                                    </h2>
                                    {/* <h2 className="pt-3" style={{ color: 'white',fontStyle:'italic',transform:'scale(0.8)' }}>
                                        (undangan hanya dihadiri oleh keluarga dan kerabat dekat)
                                    </h2> */}

                                    <h2 className="pt-3" style={{ color: cmain }}>
                                        <b style={{transform:"scale(1.5)"}}>
                                            
                                        Aston Jember Hotel Conference Center<br/>
                                        (KISHO ROOM)</b>
                    <br />Jl. Sentot Prawitodirdjo No.88 Jember Kidul, Kaliwates Jember. 
                    
                                    </h2>
                                    <Item>
                                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid white`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://goo.gl/maps/mcC61xdQFNfmrJEb6")
                      }}
                      className="p-2 mx-sm-2 btn p-0">
                      <Item>
                        
                        <p className="mb-0 my-auto " style={{ color: 'white' }}>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" 
                        className="img-fluid mx-3"
                          style={{ width: "10%", height: '10%' }} />
                          <b>Get Direction</b>
                        </p>

                      </Item>
                    </Col>
                    <Col
                                            onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NTVuNjd0dGZjZmRpdnR2NDR1aGtqMW5oZm8gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid mx-3"
                                                    style={{ width: "10%", height: '10%' }} /> <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>



                                <Ucapan
                                sesi={this.state.sesi}
                                    id={id}
                                    cmain={cmain}
                                />
                                 <Container className="py-3" >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Send Gift</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>

                                {showgift?(
                                    <Gift
                                                              reza
                                    content={
                                        [
                                            {
                                                bank: 'BRI',
                                                norek: '7748-01-003246-53-7',
                                                nama: 'Sefillaili Wardatus Istifaroh'
                                            },
                                            {
                                                bank: 'Mandiri',
                                                norek: '14300-1900-1047',
                                                nama: 'Sefillaili Wardatus Istifaroh '
                                            },
                                            
                                        ]
                                    }
                                    caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                    ccaption="white"
                                    color={'white'}
                                    bg={cmain}
                                    langsung
                                    alamat=" Jalan Mastrip IX no 52 Kelurahan Sumbersari Kecamatan Sumbersari Jember (Kos Bugenvil)"
                                />
                                ):false}
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4'>
                                                            Dari sekian miliar manusia di dunia, Tidak ada manusia yang sempurna.. Tetapi sejak aku dan kamu menjadi Kita, Kesempurnaan cinta ialah hal Yang Nyata. </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>


                                <Foot ig={logoig} />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

